import { useTranslation } from 'react-i18next';

export enum Language {
  en = 'en',
  de = 'de'
}

type LanguageSwitchProps = {
  currentLng: Language;
};

const LanguageToggle = ({ currentLng }: LanguageSwitchProps) => {
  const { i18n } = useTranslation();
  const onChange = () => {
    const language = currentLng === 'en' ? 'de' : 'en';
    i18n.changeLanguage(language);
  };

  const getLngCode = () => (currentLng === 'en' ? 'DE' : 'EN');

  return <input type="button" value={getLngCode()} onClick={onChange} className="navbar-btn" />;
};

export default LanguageToggle;
