import HeroSection from 'domains/HeroSection';
import AboutSection from 'domains/AboutSection';
import ServiceSection from '../domains/ServiceSection';
import ContactSection from '../domains/ContactSection';
import Experience from 'domains/Experience';

const Home = () => (
  <>
    <HeroSection />
    <AboutSection />
    <Experience />
    <ServiceSection />
    <ContactSection />
  </>
);

export default Home;
