import ReactDOM from 'react-dom/client';
import App from './App';
import './locales/i18n';
import '@fontsource/oswald/400.css';
import '@fontsource/oswald/700.css';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);
