import React from 'react';
import { I18nextProvider } from 'react-i18next';
import NavBar from 'ui/NavBar';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from 'routes/Home';
import LegalDisclosure from 'routes/LegalDisclosure';
import Footer from 'ui/Footer';
import i18n from 'locales/i18n';
import PrivacyPolicy from 'routes/PrivacyPolicy';
import PageNotFound from 'ui/PageNotFound';
import ScrollToTop from 'utils/ScrollToTop';

function App() {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <ScrollToTop />
          <NavBar />
          <main>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/legal-disclosure" element={<LegalDisclosure />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </main>
          <Footer />
        </I18nextProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
}

export default App;
