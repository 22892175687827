import Section from 'ui/Section';
import { Form, Formik, Field, FieldProps, FormikProps } from 'formik';
import * as Yup from 'yup';
import Text from 'ui/Text';
import FadeInOnViewportVisibility from 'ui/FadeInOnViewportVisibility';
import { useTranslation } from 'react-i18next';
import emailjs from '@emailjs/browser';
import TextInput from 'ui/TextInput';
import ErrorMessage from 'ui/ErrorMessage';
import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

type InitialFormValues = {
  email: string;
  subject: string;
  content: string;
};

const ContactFormValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('section.contact.form.error.email.invalid')
    .required('section.contact.form.error.email.required'),
  subject: Yup.string().required('section.contact.form.error.subject.required'),
  content: Yup.string().required('section.contact.form.error.content.required')
});

type Toast = {
  txt: string;
  status: 'success' | 'error';
};

const ContactSection = () => {
  const [toast, setToast] = useState<Toast>();
  const { t } = useTranslation();
  const {
    REACT_APP_EMAIL_SERVICE_ID,
    REACT_APP_EMAIL_SERVICE_TEMPLATE,
    REACT_APP_EMAIL_SERVICE_PUBLIC_KEY,
    REACT_APP_CONTACT_EMAIL
  } = process.env;

  const initialValues: InitialFormValues = {
    email: '',
    subject: '',
    content: ''
  };

  // @ts-ignore
  const isInvalid = (name: string, form: FormikProps) => !!(form.errors[name] && form.touched[name]);

  const showToast = (description: string, status: 'success' | 'error') => {
    let descriptionTranslation = t(description);

    if (status === 'error') {
      descriptionTranslation = descriptionTranslation.replace('{{email}}', REACT_APP_CONTACT_EMAIL as string);
    }

    setToast({
      txt: descriptionTranslation,
      status
    });

    setTimeout(() => setToast(undefined), 10000);
  };

  const onSubmit = async (values: InitialFormValues) => {
    try {
      await emailjs.send(
        REACT_APP_EMAIL_SERVICE_ID as string,
        REACT_APP_EMAIL_SERVICE_TEMPLATE as string,
        {
          subject: values.subject,
          reply_to: values.email,
          body: values.content
        },
        REACT_APP_EMAIL_SERVICE_PUBLIC_KEY as string
      );
      showToast('section.contact.form.submission.success', 'success');
    } catch (e) {
      showToast('section.contact.form.submission.error', 'error');
    }
  };

  return (
    <Section
      id="contact"
      title={t('section.contact.title')}
      subtitle={
        <div className="text-center">
          <Text>{t('section.contact.subtitle')}</Text>
        </div>
      }
      className="items-center">
      <div className="w-[300px] md:w-[400px]">
        <FadeInOnViewportVisibility>
          <Formik initialValues={initialValues} validationSchema={ContactFormValidationSchema} onSubmit={onSubmit}>
            {(props) => (
              <Form className="flex flex-col gap-3">
                <Field name="email">
                  {({ field, form }: FieldProps) => (
                    <TextInput
                      id="email"
                      label={t('section.contact.form.label.email')}
                      type="email"
                      placeholder={t('section.contact.form.placeholder.email')}
                      className={isInvalid('email', form) ? 'input-error' : ''}
                      {...field}
                    />
                  )}
                </Field>
                <Field name="subject">
                  {({ field, form }: FieldProps) => (
                    <TextInput
                      id="subject"
                      label={t('section.contact.form.label.subject')}
                      type="text"
                      placeholder={t('section.contact.form.placeholder.subject')}
                      className={isInvalid('subject', form) ? 'input-error' : ''}
                      {...field}
                    />
                  )}
                </Field>
                <Field name="content">
                  {({ field, form }: FieldProps) => (
                    <div className="form-control max-w-md">
                      <label className="label">
                        <span className="label-text">{t('section.contact.form.label.content')}</span>
                      </label>
                      <textarea
                        id="content"
                        className={`textarea textarea-bordered h-24 bg-white text-blackAlpha-90 mb-1 ${
                          isInvalid('content', form) ? 'textarea-error' : ''
                        }`}
                        placeholder={t('section.contact.form.placeholder.content')}
                        {...field}
                      />
                      <ErrorMessage name="content" />
                    </div>
                  )}
                </Field>
                <div className="flex mt-10 justify-center">
                  <input
                    type="submit"
                    className="btn btn-wide rounded-full bg-green text-white hover:cursor-pointer hover:scale-105"
                    value={t('section.contact.form.submit')}
                    disabled={props.isSubmitting}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </FadeInOnViewportVisibility>
      </div>
      <AnimatePresence>
        {toast && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            exit={{ opacity: 0 }}
            className="toast toast-center min-w-max">
            <div className={`alert ${toast.status === 'success' ? 'alert-success' : 'alert-error'}`}>
              <div>
                <span>{toast.txt}</span>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </Section>
  );
};

export default ContactSection;
