import React from 'react';
import FadeInOnViewportVisibility from './FadeInOnViewportVisibility';

type SectionProps = {
  id?: string;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  className?: string;
  children: React.ReactNode;
};

const Section = ({ children, title, subtitle, id, className = '' }: SectionProps) => {
  return (
    <section
      id={id}
      className={`flex flex-col w-screen py-[40px] px-[10px] md:px-[20px] justify-center min-h-[calc(100vh_-_64px)] first:min-h-screen even:bg-section-even odd:bg-section-odd ${className}`}>
      {title && (
        <FadeInOnViewportVisibility>
          <h2 className="text-center text-4xl md:text-5xl border-b-2 border-separator pb-2 mb-5">{title}</h2>
        </FadeInOnViewportVisibility>
      )}
      {subtitle && <FadeInOnViewportVisibility>{subtitle}</FadeInOnViewportVisibility>}
      <div className={`${title ? 'mt-[40px] md:mt-[60px]' : 0}`} />
      {children}
    </section>
  );
};

export default Section;
