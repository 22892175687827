import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { IoLogoLinkedin, IoLogoXing } from 'react-icons/io5';
import Text from 'ui/Text';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="flex flex-col md:flex-row justify-between p-5 bg-section-odd border-t-[1px] border-t-gray gap-y-10">
      <div className="flex flex-1 flex-col md:flex-row items-center order-2 md:order-1 gap-5">
        <RouterLink to="/legal-disclosure">{t('section.footer.legal-disclosure')}</RouterLink>
        <RouterLink to="/privacy-policy">{t('section.footer.privacy-policy')}</RouterLink>
      </div>

      <div className="flex flex-1 justify-center order-1 md:order-2 gap-3">
        <a href="https://www.linkedin.com/in/marius-stiedl-freelance-software-engineer">
          <IoLogoLinkedin size={24} aria-label="My LinkedIn profil" />
        </a>
      </div>

      <div className="flex flex-1 justify-center md:justify-end order-3">
        <Text>Copyright &copy;{new Date().getFullYear()} Marius Stiedl</Text>
      </div>
    </footer>
  );
};

export default Footer;
