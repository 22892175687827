import { animate, motion, useMotionValue, useTransform } from 'framer-motion';
import Text from './Text';
import { useTrackVisibility } from 'react-intersection-observer-hook';
import { useEffect } from 'react';
type Props = {
  circleTxt: string;
  label: string;
  duration: number;
};

const CircleProgress = ({ circleTxt, label, duration }: Props) => {
  const [ref, { isVisible, wasEverVisible }] = useTrackVisibility();
  const value = useMotionValue(0);
  const input = [0, 70, 90, 100];
  const output = [0, 0, 0.5, 1];
  const opacity = useTransform(value, input, output, { clamp: false });

  useEffect(() => {
    if (isVisible || wasEverVisible) {
      animate(value, 100, { duration, ease: 'easeOut' });
    }
  }, [isVisible]);

  return (
    <div ref={ref} className="text-center space-y-6">
      <motion.div
        className="radial-progress"
        style={{ '--size': '12rem', '--thickness': '4px', '--value': value } as React.CSSProperties}>
        <motion.div style={{ opacity }}>{circleTxt}</motion.div>
      </motion.div>
      <Text>{label}</Text>
    </div>
  );
};

export default CircleProgress;
