import Section from 'ui/Section';
import FadeInOnViewportVisibility from 'ui/FadeInOnViewportVisibility';
import { Link } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import Text from 'ui/Text';

const HeroSection = () => {
  const { t } = useTranslation();

  return (
    <Section id="home" className="p-x-0 p-y-0">
      <div className="hero min-h-screen">
        <div className="hero-content text-center">
          <div className="max-w-md">
            <FadeInOnViewportVisibility duration={2} className="text-center">
              <h1 className="text-3xl md:text-5xl font-bold">{t('section.hero.title')}</h1>
              <FadeInOnViewportVisibility delay={0.5}>
                <Text className="md:text-lg py-6">{t('section.hero.subtitle')}</Text>
              </FadeInOnViewportVisibility>
            </FadeInOnViewportVisibility>
            <FadeInOnViewportVisibility delay={1} className="mt-[20px]">
              <Link
                to="contact"
                spy
                smooth
                offset={-64}
                className="btn btn-wide text-white rounded-full border-2 border-gray hover:border-black hover:scale-105 hover:animate-none hover:bg-black hover:bg-none cursor-pointer bg-gradient-to-br from-gray to-background bg-[length:400%_400%] animate-bgSliding"
                rel="nofollow">
                {t('section.hero.hire-btn')}
              </Link>
            </FadeInOnViewportVisibility>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center"></div>
    </Section>
  );
};

export default HeroSection;
