import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Section from 'ui/Section';
import Text from 'ui/Text';

const PageNotFound = () => {
  useEffect(() => {
    document.title = '404 - Page not found';
  }, []);

  return (
    <Section id="page-not-found">
      <div className="flex flex-col text-center space-y-2">
        <h2 className="text-xl mb-3">404 - Ooooops</h2>
        <Text>The page you are looking for does not exist.</Text>
        <Link to="/">Let`s go back Home</Link>
      </div>
    </Section>
  );
};

export default PageNotFound;
