import parse from 'html-react-parser';
import Section from 'ui/Section';
import { useTranslation } from 'react-i18next';

const LegalDisclosureEN = `
<h1>Site Notice</h1>

<h2>Information pursuant to Sect. 5 German Telemedia Act (TMG)</h2>
<p>Marius Stiedl<br />
Marius Stiedl - Software Engineer<br />
Dorfstra&szlig;e, 5<br />
09322 Penig</p>

<h2>Contact</h2>
<p>Phone: +49 15239549446<br />
E-mail: info@mariusstiedl.com</p>

<h2>VAT ID</h2>
<p>Sales tax identification number according to Sect. 27 a of the Sales Tax Law:<br />
222/278/02514</p>

<h2>Information regarding professional liability insurance</h2>
<p><strong>Name and registered office of the insurer:</strong><br />
exali AG<br />
Franz-Kobinger-Str. 9<br />
86157 Augsburg <br />
Germany</p>
<p><strong>Scope of insurance:</strong><br />Weltweit</p>

<h2>Dispute resolution proceedings in front of a consumer arbitration board</h2>
<p>We are not willing or obliged to participate in dispute resolution proceedings in front of a consumer arbitration board.</p>`;

const legalDisclosureDE = `
<h1>Impressum</h1>

<h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
<p>Marius Stiedl<br />
Marius Stiedl - Software Engineer<br />
Dorfstra&szlig;e, 5<br />
09322 Penig</p>

<h2>Kontakt</h2>
<p>Telefon: +49 15239549446<br />
E-Mail: info@mariusstiedl.com</p>

<h2>Umsatzsteuer-ID</h2>
<p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
222/278/02514</p>

<h2>Angaben zur Berufs&shy;haftpflicht&shy;versicherung</h2>
<p><strong>Name und Sitz des Versicherers:</strong><br />
exali AG<br />
Franz-Kobinger-Str. 9<br />
86157 Augsburg <br />
Germany</p>
<p><strong>Geltungsraum der Versicherung:</strong><br />Weltweit</p>

<h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
<p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
`;

const LegalDisclosure = () => {
  const { i18n } = useTranslation();
  return (
    <Section id="legal-disclosure" className="items-left">
      {i18n.language === 'de' ? parse(legalDisclosureDE) : parse(LegalDisclosureEN)}
    </Section>
  );
};

export default LegalDisclosure;
