import React from 'react';

export type CardProps = {
  icon: React.ReactNode;
  title: string;
  children: React.ReactNode;
  className?: string;
};

const Card = ({ icon, title, children, className = '' }: CardProps) => (
  <article className={`flex-1 card rounded-md ${className}`}>
    <div className="card-body">
      <div className="flex flex-col items-center">{icon}</div>
      <div className="divider">
        <h4 className="card-title">{title}</h4>
      </div>
      {children}
    </div>
  </article>
);

export default Card;
