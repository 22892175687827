import Section from 'ui/Section';
import Text from 'ui/Text';
import FadeInOnViewportVisibility from 'ui/FadeInOnViewportVisibility';
import { useTranslation } from 'react-i18next';

const AboutSection = () => {
  const { t } = useTranslation();

  return (
    <Section id="about" className="relative">
      <FadeInOnViewportVisibility threshold={0.3}>
        <div className="flex flex-col items-center">
          <div className="avatar bg-borderShadow-light rounded-full p-1">
            <div className="w-48 rounded-full">
              <img src={require('../assets/images/profil.webp')} alt="A profil picture of Marius in the nature" />
            </div>
          </div>
          <h3 className="mt-5 text-xl md:-2xl text-center">{t('section.about.title')}</h3>

          <div className="max-w-[700px] py-5 px-2 md:px-5 text-center">
            <Text className="m-5">{t('section.about.introduction')}</Text>
            <Text>{t('section.about.description')}</Text>
          </div>
        </div>
      </FadeInOnViewportVisibility>
    </Section>
  );
};

export default AboutSection;
