import React from 'react';

type Props = {
  className?: string;
  children: React.ReactNode;
};

const Text = ({ children, className = '' }: Props) => <p className={`text-md ${className}`}>{children}</p>;

export default Text;
