import { GiHamburgerMenu } from 'react-icons/gi';
import { AiOutlineClose } from 'react-icons/ai';
import { useState, useEffect, useRef } from 'react';
import throttle from 'lodash/throttle';
import LanguageToggle, { Language } from './LanguageToggle';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import NavBarLogo from './NavBarLogo';
import NavBarScrollLink from './NavBarScrollLink';
import NavBarRouteLink from './NavBarRouteLink';

const HomeLinks = [
  { text: 'header.nav.home', to: 'home' },
  { text: 'header.nav.about', to: 'about' },
  { text: 'header.nav.experience', to: 'experience' },
  { text: 'header.nav.service', to: 'service' },
  { text: 'header.nav.contact', to: 'contact' }
];

const NavBar = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const [isSticky, setSticky] = useState(false);
  const headerRef = useRef(null);

  const handleScroll = (headerHeight: number) => {
    if (window.scrollY > headerHeight) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  const getNavigation = () => {
    return location.pathname === '/' ? (
      HomeLinks.map((link) => (
        <NavBarScrollLink key={link.text} to={link.to} onClick={() => setOpen(false)}>
          {t(link.text)}
        </NavBarScrollLink>
      ))
    ) : (
      <NavBarRouteLink to="/" onClick={() => setOpen(false)}>
        {t('header.nav.home')}
      </NavBarRouteLink>
    );
  };

  useEffect(() => {
    // @ts-ignore
    const header = headerRef.current.getBoundingClientRect();
    const handleScrollEvent = throttle(() => {
      handleScroll(header.height);
    }, 300);

    window.addEventListener('scroll', handleScrollEvent);

    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  }, []);

  return (
    <header
      ref={headerRef}
      className={`fixed flex flex-row px-5 ${isSticky ? 'py-[25px]' : 'py-[50px]'} ${
        isSticky || isOpen ? 'bg-gray' : 'bg-transparent'
      } z-50 top-0 left-0 right-0 h-[64px] transition-all ease-in-out duration-300`}>
      <div className="flex flex-1 md:flex-0 md:flex-grow-0 items-center">
        <div className={'navbar-btn md:hidden'}>
          {isOpen ? (
            <AiOutlineClose size={24} onClick={() => setOpen(false)} />
          ) : (
            <GiHamburgerMenu size={24} onClick={() => setOpen(true)} />
          )}
        </div>
        <div className="hidden md:inline-block">
          <NavBarLogo />
        </div>
      </div>

      <div className="flex-0 md:hidden self-center">
        <NavBarLogo />
      </div>

      <div className="flex flex-1 justify-end">
        <nav className="space-x-4 hidden md:flex mr-4 items-center">{getNavigation()}</nav>
        <div className="self-center">
          <LanguageToggle currentLng={i18n.language as Language} />
        </div>
      </div>
      {isOpen && (
        <div className={`${isSticky ? 'top-[64px]' : 'top-[84px]'} w-full absolute -ml-5 px-5 bg-gray md:hidden`}>
          <nav className="py-4 flex flex-col space-y-2">{getNavigation()}</nav>
        </div>
      )}
    </header>
  );
};

export default NavBar;
