import React from 'react';
import { motion } from 'framer-motion';
import { useTrackVisibility } from 'react-intersection-observer-hook';

type FadeInOnViewportVisibilityProps = {
  children: React.ReactNode;
  threshold?: number;
  delay?: number;
  duration?: number;
  className?: string;
};

const FadeInOnViewportVisibility = ({
  children,
  duration = 1,
  threshold = 0.5,
  delay = 0,
  className = ''
}: FadeInOnViewportVisibilityProps) => {
  const [ref, { isVisible, wasEverVisible }] = useTrackVisibility({ threshold });

  return (
    <motion.div
      ref={ref}
      className={`${className}`}
      initial={{ opacity: 0 }}
      animate={isVisible || wasEverVisible ? { opacity: 1 } : {}}
      transition={{ delay, duration }}>
      {children}
    </motion.div>
  );
};

export default FadeInOnViewportVisibility;
