import Card, { CardProps } from 'ui/Card';
import FadeInOnViewportVisibility from 'ui/FadeInOnViewportVisibility';

const ServiceCard = ({ icon, title, children }: CardProps) => {
  return (
    <FadeInOnViewportVisibility threshold={0.5} className="basis-[325px] md:basis-[400px]">
      <Card
        icon={icon}
        title={title}
        className="bg-background shadow-[5px_5px_15px_rgb(169,184,187,0.5)] min-h-[310px]">
        {children}
      </Card>
    </FadeInOnViewportVisibility>
  );
};

export default ServiceCard;
