import Section from '../ui/Section';
import { IoCodeSlash } from 'react-icons/io5';
import { IoIosDesktop } from 'react-icons/io';
import { ImMobile } from 'react-icons/im';
import ServiceCard from './ServiceCard';
import Text from 'ui/Text';
import { useTranslation } from 'react-i18next';

const ServiceSection = () => {
  const { t } = useTranslation();

  return (
    <Section
      id="service"
      title={t('section.service.title')}
      subtitle={
        <div className="text-center">
          <Text>{t('section.service.subtitle')}</Text>
        </div>
      }
      className="items-center">
      <div className="flex w-full flex-row flex-wrap justify-center gap-10">
        <ServiceCard icon={<IoCodeSlash size={64} />} title={t('section.service.card.backend.title')}>
          <Text>{t('section.service.card.backend.description')}</Text>
        </ServiceCard>

        <ServiceCard icon={<IoIosDesktop size={64} />} title={t('section.service.card.frontend.title')}>
          <Text>{t('section.service.card.frontend.description')}</Text>
        </ServiceCard>

        <ServiceCard icon={<ImMobile size={64} />} title={t('section.service.card.mobile.title')}>
          <Text>{t('section.service.card.mobile.description')}</Text>
        </ServiceCard>
      </div>
    </Section>
  );
};

export default ServiceSection;
