import { useTranslation } from 'react-i18next';
import Section from 'ui/Section';
import CircleProgress from 'ui/CircleProgress';
import FadeInOnViewportVisibility from 'ui/FadeInOnViewportVisibility';

const Experience = () => {
  const { t } = useTranslation();
  return (
    <Section id="experience" title={t('section.experience.title')} className="items-center">
      <div className="flex flex-col md:flex-row flex-wrap gap-10 justify-center">
        <FadeInOnViewportVisibility>
          <CircleProgress
            label={t('section.experience.backend')}
            circleTxt={`+${new Date().getFullYear() - 2017} ${t('word.years')}`}
            duration={3}
          />
        </FadeInOnViewportVisibility>
        <FadeInOnViewportVisibility>
          <CircleProgress
            label={t('section.experience.frontend')}
            circleTxt={`+${new Date().getFullYear() - 2018} ${t('word.years')}`}
            duration={2}
          />
        </FadeInOnViewportVisibility>
        <FadeInOnViewportVisibility>
          <CircleProgress
            label={t('section.experience.mobile')}
            circleTxt={`+${new Date().getFullYear() - 2020} ${t('word.years')}`}
            duration={1}
          />
        </FadeInOnViewportVisibility>
        <FadeInOnViewportVisibility>
          <CircleProgress label={t('section.experience.customer')} circleTxt="4" duration={1.5} />
        </FadeInOnViewportVisibility>
      </div>
    </Section>
  );
};

export default Experience;
