type LogoProps = {
  size?: string;
};
const Logo = ({ size = '64' }: LogoProps) => (
  <svg width={size} height={size} viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_1_9)">
      <path
        d="M29.048 65L21.5041 61.5456L18.0951 55.0081L20.9991 40.5589L7.19355 37.5L1 30.0842L13.9032 12.5L27.8387 2.5L31.9677 1H40.2258L52.6129 9.5L65 30.567L61.3871 37L56.6985 40.5589H46.5L49 54.5L45.6825 60.5L41.516 64.5543L29.048 65Z"
        fill="#29383B"
      />
      <path
        d="M18.0951 55.0081L27.5645 58.4626M18.0951 55.0081L21.5041 61.5456L29.048 65M18.0951 55.0081L20.9991 40.5589M27.5645 58.4626L29.048 65M27.5645 58.4626L44.6409 57.794M27.5645 58.4626L29.048 40.5589M29.048 65L41.516 64.5543M44.6409 57.794L41.516 64.5543M44.6409 57.794L49 54.5M44.6409 57.794L42.3367 40.5589M41.516 64.5543L45.6825 60.5L49 54.5M49 54.5L46.5 40.5589M20.9991 40.5589H29.048M20.9991 40.5589L7.19355 37.5L1 30.0842M20.9991 40.5589L16.9273 30.0842M29.048 40.5589H42.3367M42.3367 40.5589H46.5M46.5 40.5589H56.6985M1 30.0842H16.9273M1 30.0842L13.9032 12.5M16.9273 30.0842L61.9032 30.567M16.9273 30.0842L24.8815 8.68891M56.6985 40.5589L61.9032 30.567M56.6985 40.5589L61.3871 37L65 30.567M61.9032 30.567H65M61.9032 30.567L51.0645 9.5M65 30.567L52.6129 9.5M13.9032 12.5L24.8815 8.68891M13.9032 12.5L27.8387 2.5L31.9677 1M24.8815 8.68891L51.0645 9.5M24.8815 8.68891L31.9677 1M51.0645 9.5H52.6129M51.0645 9.5L40.2258 1M52.6129 9.5L40.2258 1M31.9677 1H40.2258"
        stroke="#D4DBDD"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1_9"
        x="0.0776"
        y="0.5"
        width="65.7867"
        height="65.5791"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.287604" />
        <feGaussianBlur stdDeviation="0.143802" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_9" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_9" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default Logo;
