import { InputHTMLAttributes } from 'react';
import ErrorMessage from './ErrorMessage';

type Props = InputHTMLAttributes<HTMLInputElement> & {
  id: string;
  label: string;
};

const TextInput = ({ id, label, className, type, placeholder, ...restProps }: Props) => (
  <div className="form-control w-full max-w-md">
    <label className="label">
      <span className="label-text">{label}</span>
    </label>
    <input
      id={id}
      type={type}
      placeholder={placeholder}
      className={`input input-bordered w-full max-w-md bg-white text-blackAlpha-90 mb-1 ${className}`}
      {...restProps}
    />
    <ErrorMessage name={id} />
  </div>
);

export default TextInput;
