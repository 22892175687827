import { ErrorMessage as FormikErrorMessage } from 'formik';
import { useTranslation } from 'react-i18next';

type Props = {
  name: string;
};

const ErrorMessage = ({ name }: Props) => {
  const { t } = useTranslation();
  return (
    <FormikErrorMessage name={name}>
      {(errorKey) => <p className="text-rose-300 text-left">{t(errorKey)}</p>}
    </FormikErrorMessage>
  );
};

export default ErrorMessage;
